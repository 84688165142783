export default [
    {
        id: 'sprint',
        name: 'Спринт',
        icon: ''
    },
    {
        id: 'list',
        name: 'Бэклог',
        icon: ''
    },
]