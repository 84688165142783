<template>
    <div>
        <EditDrawer />
        <TaskShowDrawer />
        <SprintShowDrawer />
    </div>
</template>

<script>
import store from "./store/index"
import EditDrawer from './components/EditDrawer'
import TaskShowDrawer from './components/TaskShowDrawer'
import SprintShowDrawer from './components/Sprint/SprintShowDrawer.vue'
export default {
    components: {
        EditDrawer,
        TaskShowDrawer,
        SprintShowDrawer
    },
    created() {
        if(!this.$store.hasModule('task')) {
            this.$store.registerModule("task", store)
            this.$store.dispatch('task/initFormInfo')
        }
    }
}
</script>